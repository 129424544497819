<script setup>
import { email, helpers, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import TrackingFormInput from '~/components/TrackingFormInput.vue';

const headerMenu = ref([
    { name: 'Home', link: '/', icon: 'ph:house-line-duotone' },
    { name: 'About', link: '/about', icon: 'ph:circle-wavy-warning-duotone' },
    { name: 'Why FTS', link: '/why-fts', icon: 'ph:circle-wavy-question-duotone' },
    { name: 'Services', link: '/services', icon: 'ph:grid-four-duotone' },
    // { name: 'Contact', link: '/contact', icon: 'ph:envelope-duotone' },
]);

const contactButton = useSettingValue('header_button');

const brand = ref({
    name: useSettingValue('app_name') ?? 'Fast Track Shipping',
    logo: useSettingValue('app_logo'),
    logoDark: useSettingValue('app_logo_dark'),
});
const contactModal = ref(false);

const contactForm = reactive({
    name: null,
    email: null,
    subject: null,
    content: null,
    countryId: null,
});

const resetContactForm = async () => {
    v$.value.$reset();
    contactForm.name = null;
    contactForm.email = null;
    contactForm.subject = null;
    contactForm.content = null;
    contactForm.countryId = null;
};

const rules = reactive({
    name: { required: helpers.withMessage('Name is required', required) },
    email: {
        required: helpers.withMessage('Email is required', required),
        email: helpers.withMessage('Invalid email format', email),
    },
    subject: { required: helpers.withMessage('Subject is required', required) },
    content: { required: helpers.withMessage('Message is required', required) },
    countryId: { required: helpers.withMessage('Country is required', required) },
});
const v$ = useVuelidate(rules, contactForm);

async function closeContactModal() {
    contactModal.value = false;
    await resetContactForm();
}

function openContactModal() {
    contactModal.value = true;
}

const route = useRoute();
const isMenuOpen = ref(false);
watch(
    () => route.path,
    (newPath, oldPath) => {
        if (newPath !== oldPath) {
            isMenuOpen.value = false;
        }
    },
);
const sticky = ref(false);

const handleScroll = () => {
    sticky.value = window.scrollY <= 40;
};

// Add event listeners on mount and remove on unmount
onMounted(() => {
    handleScroll();
    document.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
    document.removeEventListener('scroll', handleScroll);
});
</script>

<template>
    <div :class="[sticky ? '!bg-transparent absolute w-full top-0' : 'bg-white z-50 w-full top-0 sticky shadow-lg', '  px-2  transition-all']">
        <div :class="!sticky ? 'py-1' : 'py-3'" class="mx-auto container px-8 ease-in-out duration-300">
            <div :class="sticky && 'text-white'" class="flex items-center justify-between gap-8 duration-300 w-full">
                <div class="intro-x shrink-0">
                    <NuxtLink :to="'/'">
                        <NuxtImg
                            v-if="brand.logo"
                            :alt="brand.name"
                            :class="[sticky ? 'h-28' : 'h-16', 'w-full transition-all']"
                            :src="!sticky ? brand.logo : brand.logoDark"
                            :title="brand.name"
                            height="100%"
                            loading="lazy"
                            preload
                            quality="80"
                            width="100%"
                        />
                    </NuxtLink>
                </div>
                <ul class="-intro-y lg:flex items-center gap-4 text-[14px] xl:text-[16px] hidden">
                    <template v-for="item in headerMenu" :key="item.link">
                        <li class="group relative">
                            <NuxtLink :to="item.link" class="relative whitespace-nowrap flex items-center px-4 py-8">
                                <Icon :class="sticky ? 'text-slate-100 group-hover:text-warning' : 'text-slate-400 group-hover:text-primary'" :name="item.icon" class="z-20 mr-2 h-6 w-6 ease-in-out !duration-50" />
                                <span :class="sticky ? 'group-hover:text-warning' : 'group-hover:text-primary'" class="z-20 font-semibold ease-in-out !duration-50">{{ item.name }}</span>
                            </NuxtLink>
                        </li>
                    </template>
                </ul>
                <button :class="['group px-6 btn btn-rounded btn-' + contactButton.style, '-intro-x md:block hidden']" @click="openContactModal">
                    <Icon v-if="contactButton.icon" :name="contactButton.icon" class="mr-2 h-5 w-5" />
                    {{ contactButton.label }}
                </button>
            </div>
        </div>
        <TheModal :open-modal="contactModal" size="3xl" static @close-modal="closeContactModal">
            <template #content>
                <TrackingFormInput />
            </template>
        </TheModal>
    </div>
</template>
